<template>
  <div style="height: inherit">
    <BModal
      v-model="modalshow"
      title="Promoción"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :ok-disabled="true"
      img-width="200"
      img-height="200"
    >
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <div class="bg-light-primary rounded-top text-center">
          <b-carousel
            id="carousel-interval"
            controls
            indicators
            :interval="1000"
          >
            <b-carousel-slide
              v-for="(fotos, index) in listFotos"
              :key="index"
            >
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  width="200"
                  height="200"
                  :src="fotos"
                >
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <b-card-body>
          <!-- metting header -->
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day">
              <small
                class="text-muted d-block"
                style="color: black !important"
              >
                Del {{ itemSelected.fechaInicio }} al {{ itemSelected.fechaFin }}
              </small>
              <small
                class="text-muted d-block mt-1"
                style="color: black !important"
              >
                Registrado {{ itemSelected.fechaRegistro }}
              </small>
            </div>
            <div class="my-auto">
              <b-card-title class="mb-2">
                {{ itemSelected.title }}
              </b-card-title>
              <b-card-text class="mb-2">
                {{ itemSelected.descripcion }}
              </b-card-text>
              <b-card-title class="mb-2">
                TÉRMINOS Y CONDICIONES
              </b-card-title>
              <b-card-text class="mb-0">
                {{ itemSelected.terminosCondiciones }}
              </b-card-text>
            </div>
          </div>
          <!--/ metting header -->

          <!-- media -->
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="MapPinIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ itemSelected.destino }} - {{ itemSelected.place.titulo }} -
                {{ itemSelected.place.servicio }}
              </h6>
              <small>{{ itemSelected.direccion }}</small>
            </b-media-body>
          </b-media>
        </b-card-body>
      </b-card>
    </BModal>
    <PromocionesForm
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <modal-multimedia
      ref="refModalMultimedia"
      :data-info="dataInfo"
    />
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalItems }} resultados encontrados
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar Nuevo</span>
              </b-button>

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
                class="ml-1"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="query"
              placeholder="Buscar registro"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="isBusy"
      class="text-center text-primary my-2"
    >
      <b-spinner
        variant="primary"
        class="align-middle"
      />
      <strong class="ml-1">Cargando...</strong>
    </div>

    <!-- Lista de Registros -->
    <section
      :class="itemView"
      style="margin-top: 1rem"
    >
      <b-card
        v-for="registro in registros"

        :key="registro.idPromocion"
        class="ecommerce-card bg-dark"
        no-body
      >
        <div
          class="item-img text-center"
          style="padding-bottom: 0px; margin: auto"
        >
          <b-img
            v-if="registro.url"
            :alt="`${registro.nombre}`"
            fluid
            class="card-img-top"
            :src="`${pathMultimedia}/byUrl?isthumb=true&url=${registro.url}`"
          />
          <b-img
            v-else
            :alt="`${registro.nombre}`"
            fluid
            class="card-img-top"
            :src="require('@/assets/images/checkapp/notimage.png')"
          />
        </div>

        <!-- Registro Detalle -->
        <b-card-body class="bg-white">
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{ 'ml-25': star - 1 }"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[
                      { 'fill-current': star <= registro.directorio.ranking },
                      star <= registro.directorio.ranking ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <div>
              <b-badge
                v-if="registro.estadoPromocion === 'R'"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                PENDIENTE
              </b-badge>

              <b-badge
                v-if="registro.estadoPromocion === 'A'"
                pill
                variant="light-success"
                class="text-capitalize"
              >
                APROBADO
              </b-badge>

              <b-badge
                v-if="registro.estadoPromocion === 'D'"
                v-b-tooltip.hover.v-danger
                :title="`El ${ moment(String(registro.publishDisapproval)).format('DD/MM/YYYY') }
                Motivo: ${registro.disapprovalReasons}`"
                pill
                variant="light-danger"
                class="text-capitalize"
              >
                DESAPROBADO
              </b-badge>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              v-b-tooltip.hover.top="registro.titulo"
              class="text-body"
              :href="registro.url"
              target="_blank"
            >
              {{ registro.titulo }}
            </b-link>
            <b-card-text class="item-company">
              By
              <b-link class="ml-25">
                {{ registro.directorio.nombreComercial }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            <div class="item-wrapper">
              <div>
                {{ registro.destino.nombre }}
              </div>
              <div>
                Del {{ registro.fechaInicio }} al {{ registro.fechaFin }}
              </div>

            </div>
            <div class="item-wrapper">
              <div>
                {{ diasVigentes(registro) }}
              </div>

            </div>

          </b-card-text>
        </b-card-body>

        <!-- Action Buttons -->
        <div class="item-options text-center">
          <b-button
            v-b-tooltip.hover.top="'Vista Previa'"
            variant="info"
            class="btn-wishlist remove-wishlist"
            @click="showFotosModal(registro)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Editar'"
            variant="primary"
            class="btn-wishlist remove-wishlist"
            @click="getEdit(registro)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Eliminar'"
            variant="danger"
            class="btn-cart move-cart"
            @click="deleteRegister(registro)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <!--  <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="page"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            align="center"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section> -->
    <BRow>
      <BCol
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span
          class="text-muted"
        >Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
      </BCol>
      <!-- Pagination -->
      <BCol
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </BCol>
    </BRow>

    <!-- Sidebar -->
    <!-- <portal to="content-renderer-sidebar-detached-left">
      <filter-sidebar
        v-if="dataReady"
        :filters="filters"
        :filters-options.sync="filtersOptions"
        :data-ready.sync="dataReady"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @refetch-data="getPromociones"
      />
    </portal> -->
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import moment from 'moment'
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BSpinner,
  VBTooltip,
  BModal,
  BCarousel,
  BCarouselSlide,
  BMedia,
  BMediaAside,
  BAvatar,
  BCardTitle,
  BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useNotify } from '@/helpers/toast'
import { ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import config from '@/services/config'
/* import FilterSidebar from './PromocionesFilterSidebar.vue' */
import { useShopUi } from './useECommerceShop'
import { useEcommerceUi } from './useEcommerce'
import PromocionesForm from './PromocionesForm.vue'
import ModalMultimedia from '../components/multimedia/ModalMultimedia.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ModalMultimedia,
    // BSV
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BSpinner,

    // SFC
    /* FilterSidebar, */
    PromocionesForm,
    BModal,
    BCarousel,
    BCarouselSlide,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardTitle,
    BMediaBody,
  },
  /*   computed: {
    diasVigentes(item) {
      console.log(item)
      return '6 days'
    },
  }, */
  setup(props, context) {
    const { notify } = useNotify(context)
    const modalshow = ref(false)
    const dataEdit = ref({})
    const dataInfo = ref({})
    const isToggleSidebar = ref(false)
    const registros = ref({})
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const totalItems = ref(0)
    const totalPages = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const totalRows = ref(0)
    const dataReady = ref(false)
    const isBusy = ref(false)
    const filtersOptions = ref({})
    const formType = ref('')
    const refModalMultimedia = ref(null)
    const refModalQr = ref(null)
    const idEmpresa = ref(0)
    const idTourPaquete = ref(0)
    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)
    const itemSelected = ref({
      title: '',
      descripcion: '',
      fotos: [],
      terminosCondiciones: '',
      fechaInicio: '',
      fechaFin: '',
      fechaRegistro: '',
      place: {
        titulo: '',
        servicio: '',
      },
      destino: '',
      direccion: '',
    })
    const listFotos = ref([])

    /* const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination() */

    filtersOptions.value.destinos = []
    filtersOptions.value.experiencias = []

    filtersOptions.value.estadoTourPaquete = [
      {
        text: 'Registrado',
        value: 'REGISTRADO',
      },
      {
        text: 'Publicado',
        value: 'PUBLICADO',
      },
      {
        text: 'Desactivado',
        value: 'DESACTIVADO',
      },
    ]

    const filters = ref({})
    filters.value.destino = -1
    filters.value.experiencia = -1

    const sortByOptions = ref([
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Pendientes',
        value: 'R',
      },
      {
        text: 'Aprobados',
        value: 'A',
      },
      {
        text: 'Desaprobados',
        value: 'D',
      },
    ])

    const sortBy = ref({ text: 'Todos', value: '' })
    //

    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const imagesRegister = item => {
      dataInfo.value.alertMsgTitle = 'Imágenes registradas para la promoción.'
      dataInfo.value.alertMsgSubTitle = 'Dimensiones recomendadas 1000x667, cantidad mínima ideal: 7 imágenes.'
      dataInfo.value.alertIcon = 'InfoIcon'
      refModalMultimedia.value.idSeccion = item.idAtractivo
      refModalMultimedia.value.idDestinoTuristico = item.destino.idDestinoTuristico
      refModalMultimedia.value.seccion = 'ATRACTIVOS'
      refModalMultimedia.value.orientacion = 'H'
      refModalMultimedia.value.loadRegisters()
      refModalMultimedia.value.openModal()
      // modalshow.value = true
    }

    const getModalQr = item => {
      refModalQr.value.idSeccion = item.idAtractivo
      refModalQr.value.idDestino = item.destino.idDestinoTuristico
      refModalQr.value.seccion = 'Atractivos'
      refModalQr.value.srcQR = item.qrCode
      refModalQr.value.openModal()
    }

    const editRegister = item => {
      dataEdit.value = item
      formType.value = 'edit'
      isToggleSidebar.value = true
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            filtersOptions.value.destinos.push({
              text: 'Todos',
              value: -1,
            })
            response.items.forEach(item => {
              filtersOptions.value.destinos.push({
                text: item.nombre,
                value: item.idDestinoTuristico,
              })
            })
          } else {
            filtersOptions.value.destinos = []
          }
        })
    }
    /*     const getRegistros = async () => {
      isBusy.value = true
      await store
        .dispatch('plataforma/ATRACTIVO_FIND_ALL', {
          query: query.value,
          page: page.value,
          limit: perPage.value,
          sortBy: 'idAtractivo%7CDESC',
          idDestino: filters.value.destino,
        })
        .then(response => {
          registros.value = response.items
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
        })
      isBusy.value = false
    } */
    const getPromociones = () => {
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      isBusy.value = true
      store
        .dispatch('plataforma/CONTENIDO_FIND_ALL', {
          estadoCuenta: sortBy.value.value,
          limit: perPage.value,
          page: page.value,
          query: query.value,
          idDirectorio: directorio.idDirectorio,
        })
        .then(response => {
          registros.value = response.items
          totalRows.value = response.totalRows
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
          from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
          to.value = perPage.value * (page.value - 1) + totalRows.value
          of.value = totalItems.value
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const refetchData = response => {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      notify('Operación Extitosa', message, 'success')
      getPromociones()
    }

    const deleteRegister = item => {
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('panel/PROMOCIONES_DELETE', {
              id: item.idPromocion,
            })
            .then(() => {
              notify(
                'Operación Exitosa',
                'El proceso se ha ejecutado satisfactoriamente.',
                'success',
              )
              getPromociones()
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }
    const getEdit = data => {
      const fechaInicio = moment(data.fechaInicio, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const fechaFin = moment(data.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD')

      const newObj = {
        atractivosIds: data.atractivosIds,
        directorio: data.directorio,
        idDirectorio: data.idDirectorio,
        idDestinoTuristico: data.idDestinoTuristico,
        idPromocion: data.idPromocion,
        titulo: data.titulo,
        cuerpo: data.cuerpo,
        url: data.url,
        fechaInicio,
        fechaFin,
        terminosCondiciones: data.terminosCondiciones,
        destacado: data.destacado,
        estadoPromocion: data.estadoPromocion,
        estado: data.estado,
      }
      formType.value = 'edit'
      isToggleSidebar.value = true
      dataEdit.value = newObj
    }

    const removeHtmlTags = input => {
      const regex = /(<([^>]+)>)/gi
      return input.replace(regex, '')
    }

    const getDateFmt = date => moment(String(date)).format('DD/MM/YYYY')

    const showFotosModal = item => {
      listFotos.value = []
      listFotos.value.push(
        `${pathMultimedia.value}/byUrl?isthumb=true&url=${item.url}`,
      )
      itemSelected.value = {
        title: item.titulo,
        descripcion: removeHtmlTags(item.cuerpo),
        fotos: listFotos,
        terminosCondiciones: removeHtmlTags(item.terminosCondiciones),
        fechaInicio: item.fechaInicio,
        fechaFin: item.fechaFin,
        fechaRegistro: getDateFmt(item.crAt),
        place: {
          titulo: item.directorio.nombreComercial,
          servicio: item.directorio.tipoServicioTuristico.labelFrontend,
        },
        destino: item.directorio.destino.nombre,
        direccion: item.directorio.direccion,
      }
      modalshow.value = true
    }

    const loadData = async () => {
      dataReady.value = false
      await getPromociones()
      await getDestinos()
      dataReady.value = true
    }

    loadData()

    watch([query, page, sortBy], () => {
      getPromociones()
    })

    const showError = error => {
      let errorMessage = 'Intentelo en otro momento.'
      if (error.response) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message
        }
        if (error.response.data.errorMessage) {
          errorMessage = error.response.data.errorMessage
        }
      }
      notify('Ha ocurrido un error', errorMessage, 'danger')
    }

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const { itemView, itemViewOptions, totalProducts } = useShopUi()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // Nuevos
      modalshow,
      imagesRegister,
      getModalQr,
      editRegister,
      deleteRegister,
      refModalMultimedia,
      refModalQr,
      dataEdit,
      dataInfo,
      isToggleSidebar,
      registros,
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      from,
      to,
      of,
      totalRows,
      dataReady,
      isBusy,
      filtersOptions,
      getPromociones,
      refetchData,
      //
      // useShopFiltersSortingAndPagination
      filters,
      sortBy,
      sortByOptions,
      getEdit,
      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      showError,
      openForm,
      formType,

      idEmpresa,
      idTourPaquete,
      pathMultimedia,
      showFotosModal,
      itemSelected,
      listFotos,
      moment,
    }
  },
  methods: {
    getDateFmt(fecha) {
      return moment(String(fecha)).format('DD/MM/YYYY')
    },
    diasVigentes(item) {
      const start = moment(item.fechaInicio, 'DD/MM-YYYY')
      const end = moment(item.fechaFin, 'DD/MM-YYYY')
      return `Vigencia ${moment.duration(end.diff(start)).asDays()} días`
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.ecommerce-application .grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr !important;
  -webkit-column-gap: 2rem;
  -moz-column-gap: 2rem;
  column-gap: 2rem;
}
</style>
