<template>
  <div>
    <b-modal
      v-model="modalshow"
      title="Gestión Multimedia"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :busy="isBusy"
      :ok-disabled="true"
    >
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            :icon="dataInfo.alertIcon"
          />
          <span class="ml-25"><strong>{{ dataInfo.alertMsgTitle }}</strong> {{ dataInfo.alertMsgSubTitle }}</span>
        </div>
      </b-alert>
      <file-pond
        ref="pond"
        name="archivo"
        label-idle="Arrastre los archivos aquí o <span class='filepond--label-action'>Selecciónelos</span>"
        allow-multiple="true"
        allow-reorder="true"
        accepted-file-types="image/jpeg, image/png"
        :files="myFiles"
        :server="myServer"
        @removefile="onRemoveFile"
        @reorderfiles="reorderFiles"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert, BModal,
} from 'bootstrap-vue'
import {
  ref,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import config from '@/services/config'
import { showToast, showError } from '@/helpers'

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)

export default {
  components: {
    BAlert,
    BModal,
    FilePond,
  },
  directives: {
    Ripple,
  },
  props: {
    dataInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      idDestinoTuristico: 0,
      seccion: '',
      orientacion: 'H',
      idSeccion: 0,
      myServer: {
        url: `${config.pathRepo}${config.contextBO}/api/v1/`,
        timeout: 7000,
        process: {
          url: 'multimedia',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'x-access-token': localStorage.getItem('accessToken'),
          },
          onload: response => response.key,
          onerror: response => response.data,
          ondata: formData => {
            formData.append('seccionMult', this.seccion)
            formData.append('orientacion', this.orientacion)
            formData.append('idSeccion', this.idSeccion)
            formData.append('idDestinoTuristico', this.idDestinoTuristico)
            return formData
          },
        },
        revert: (uniqueFileId, load, error) => {
          console.log('log')
          error('oh my goodness')
          load()
        },
        load: (source, load, error, progress, abort, headers) => {
          console.log(error, progress, abort, headers)
          const myRequest = new Request(source)
          fetch(myRequest).then(response => {
            response.blob().then(myBlob => {
              load(myBlob)
            })
          })
        },
      },
      myFiles: [],
    }
  },
  setup() {
    const modalshow = ref(false)
    const isBusy = ref(false)
    const { pathRepo } = config
    const pathMultimedia = ref(`${pathRepo}${config.contextBO}/api/v1/multimedia`)

    const okMdl = () => {
      console.log('entramos al okMdl')
    }

    const openModal = () => {
      modalshow.value = true
    }

    return {
      // Customs
      modalshow,
      okMdl,
      openModal,
      isBusy,
      pathMultimedia,
    }
  },
  methods: {
    onRemoveFile(err, file) {
      if (file.serverId) {
        const paramsUrl = file.serverId.split('/')
        const idMult = paramsUrl[paramsUrl.length - 1]
        store
          .dispatch('multimedia/MULTIMEDIA_DELETE', {
            id: idMult,
          })
          .then(response => {
            this.showToast('Operación Extitosa', response.message, 'success')
          })
          .catch(error => {
            this.showToast('Ha ocurrido un error', error, 'danger')
          })
      }
    },
    async reorderFiles(files) {
      let idsMult = ''
      files.forEach(file => {
        const paramsUrl = file.serverId.split('/')
        const idMult = paramsUrl[paramsUrl.length - 2]
        if (idsMult.length !== 0) {
          idsMult += '&'
        }
        idsMult += `multimedias%5B%5D=${idMult}`
      })
      await store
        .dispatch('multimedia/MULTIMEDIA_SORT', {
          multimedias: idsMult,
        })
        .then(response => {
          this.showToast('Operación Extitosa', response.message, 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    async loadRegisters() {
      this.myFiles = []
      await store
        .dispatch('multimedia/MULTIMEDIA_BY_SECCION', {
          seccion: this.seccion,
          idSeccion: this.idSeccion,
        })
        .then(response => {
          response.forEach(item => {
            this.myFiles.push({
              source: `${this.pathMultimedia}/${item.idMultimedia}/thumbnail`,
              options: {
                type: 'local',
              },
            })
          })
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    showToast,
    showError,
  },
}
</script>

<style>
.modal-header{
  align-items: center;
}
.filepond--item {
    width: calc(50% - 0.5em);
}
@media (min-width: 30em) {
    .filepond--item {
        width: calc(50% - 0.5em);
    }
}
@media (min-width: 50em) {
    .filepond--item {
        width: calc(33.33% - 0.5em);
    }
}
</style>
