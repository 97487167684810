<template>
  <b-sidebar
    id="new-edit-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6 class="text-dark">
          <feather-icon icon="ClipboardIcon" />
          <span class="align-middle ml-25">Ficha Básica</span>
        </h6>

        <hr style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <!-- Destino -->
        <b-form-group
          label="Seleccione Destino"
          label-for="destino"
        >
          <v-select
            id="destino"
            v-model="destinoSel"
            :reduce="m => m.idDestinoTuristico"
            label="nombre"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="destinos"
            @input="selectDestino"
          />
        </b-form-group>
        <!-- Atractivo turistico -->
        <b-form-group
          label="Seleccione Atractivo"
          label-for="atractivo"
        >
          <v-select
            id="atractivo"
            v-model="atractivosSelected"
            :reduce="m => m.idAtractivo"
            label="nombre"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="atractivos"
            :close-on-select="false"
            multiple
          />
        </b-form-group>

        <!-- Nombre -->
        <b-form-group
          label="Nombre Promocion"
          label-for="nombre"
        >
          <b-form-input
            id="nombre"
            v-model="formData.titulo"
          />
        </b-form-group>

        <b-form-group
          label="Fecha de Inicio"
          label-for="start-date"
        >

          <flat-pickr
            v-model="formData.fechaInicio"
            class="form-control"
          />
        </b-form-group>
        <b-form-group
          label="Fecha de Fin"
          label-for="end-date"
        >

          <flat-pickr
            v-model="formData.fechaFin"
            class="form-control"
          />

        </b-form-group>

        <!-- cuerpo -->
        <b-form-group
          label="Cuerpo"
          label-for="descripcion"
        >
          <quill-editor
            id="descripcion"
            v-model="formData.cuerpo"
            :options="editorOption"
            class="border-bottom-0"
          />
        </b-form-group>

        <!-- terminos y condiciones -->
        <b-form-group
          label="Terminos y condiciones"
          label-for="particularidad"
        >
          <quill-editor
            id="particularidad"
            v-model="formData.terminosCondiciones"
            :options="editorOption"
            class="border-bottom-0"
          />
        </b-form-group>

        <h6>
          <feather-icon icon="ImageIcon" />
          <span class="align-middle ml-25">Multimedia</span>
        </h6>

        <hr style="border-top: 2px solid #000000;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <!-- Banner -->
        <b-form-group
          label="Banner (800 x 600)"
          label-for="banner"
        >
          <b-row class="container">
            <b-col md="4">
              <b-media class="mb-2 text-center">
                <template #aside>
                  <b-avatar
                    ref="previewElBanner"
                    :src="imgBase64Banner"
                    size="100px"
                    rounded=""
                  />
                </template>
              </b-media>
            </b-col>
            <b-col md="8">
              <b-row class="mt-1">
                <b-col md="12">
                  <b-button
                    variant="info"
                    class="btn-block btn-sm"
                    @click="$refs.refInputElBanner.$el.click()"
                  >
                    <b-form-file
                      id="file"
                      ref="refInputElBanner"
                      v-model="fileBanner"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="uploadBanner"
                    />
                    <span class="d-none d-sm-inline">Seleccionar Banner</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="padding-top:10px">
                <b-col md="12">
                  <b-button
                    variant="light"
                    class="btn-block btn-sm"
                    @click="onDeleteBanner"
                  >
                    <span class="d-none d-sm-inline">Eliminar Banner</span>
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-5">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormInput, BSidebar, BForm, BFormGroup, BButton, BRow, BCol, BFormFile, BAvatar, BMedia,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import config from '@/services/config'

export default {
  components: {
    BMedia,
    BCol,
    BRow,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    quillEditor,
    flatPickr,
    BFormFile,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,

    }
  },

  setup(props, { emit }) {
    const formData = ref({})
    const titleForm = ref('')
    const destinos = ref([])
    const destinoSel = ref(null)
    const atractivos = ref([])
    const atractivosSelected = ref([])

    /* multimedia */
    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)
    const imgBase64Banner = ref(null)
    const refInputElBanner = ref(null)
    const fileBanner = ref(null)
    const previewElBanner = ref(null)
    const onDeleteBanner = () => {
      imgBase64Banner.value = null
    }
    const uploadBanner = e => {
      const fileTemp = e.target.files[0]
      fileBanner.value = fileTemp
      imgBase64Banner.value = URL.createObjectURL(fileBanner.value)
    }

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ align: [] }],
    ]

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }

    const resetForm = () => {
      formData.value = {}
      destinoSel.value = ''
      atractivosSelected.value = []
      imgBase64Banner.value = null
    }
    const atractivoFindAll = async () => {
      await store.dispatch('panel/ATRACTIVO_FIND_ALL', { limit: 1000,
        page: 1,
        query: '',
        idDestino: destinoSel.value,
        sortBy: '' }).then(res => {
        atractivos.value = res.items
      })
    }
    const selectDestino = async () => {
      atractivosSelected.value = []
      await atractivoFindAll()
    }
    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }
    const getLogo = (data, tipo) => {
      if (tipo === 'banner') imgBase64Banner.value = `${pathMultimedia.value}/byUrl?isthumb=false&url=${data.url}`
    }

    const saveForm = async () => {
      const empresa = JSON.parse(localStorage.getItem('empresa'))
      const formPostPromocion = new FormData()
      if (destinoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Destino' } } })
        return
      }

      if (atractivosSelected.length === 0) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar al menos un atractivo' } } })
        return
      }
      formPostPromocion.append('idDestinoTuristico', destinoSel.value)
      formPostPromocion.append('idDirectorio', empresa.idDirectorio)
      formPostPromocion.append('titulo', formData.value.titulo)
      formPostPromocion.append('cuerpo', formData.value.cuerpo)
      formPostPromocion.append('fechaInicio', new Date(formData.value.fechaInicio))
      formPostPromocion.append('fechaFin', new Date(formData.value.fechaFin))
      formPostPromocion.append('terminosCondiciones', formData.value.terminosCondiciones)
      formPostPromocion.append('imagen', fileBanner.value)
      formPostPromocion.append('atractivosIds', atractivosSelected.value.join(','))

      let service = 'panel/PROMOCIONES_CREATE'

      if (props.formType === 'edit') {
        service = 'panel/PROMOCIONES_UPDATE'
        formPostPromocion.append('idPromocion', formData.value.idPromocion)
      }

      await store.dispatch(service, formPostPromocion)
        .then(async response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = async val => {
      resetForm()
      emit('update:is-toggle-sidebar', val)
      getDestinos()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Promocion'
      }
      if (props.formType === 'edit') {
        titleForm.value = 'Editar Promocion'
        formData.value = props.dataEdit
        destinoSel.value = props.dataEdit.idDestinoTuristico
        await atractivoFindAll()
        /* props.dataEdit.atractivosIds.split(',')  to integer */
        atractivosSelected.value = props.dataEdit.atractivosIds.split(',').map(Number)
        imgBase64Banner.value = ''
        if (props.dataEdit.url) getLogo({ url: props.dataEdit.url, isthumb: true }, 'banner')
      }
    }

    return {
      formData,
      saveForm,
      toggleSidebar,
      resetForm,
      configDP,
      editorOption,
      titleForm,
      destinos,
      pathMultimedia,
      refInputElBanner,
      previewElBanner,
      onDeleteBanner,
      uploadBanner,
      destinoSel,
      getDestinos,
      getLogo,
      selectDestino,
      fileBanner,
      atractivos,
      atractivosSelected,
      imgBase64Banner,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

#new-edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
[dir=ltr] .ql-snow .ql-icon-picker .ql-picker-label svg {
  margin-top: -4px;
}
</style>
